// TODO: test blog redirect on dev.vizbi.org
// https://stackoverflow.com/q/73885230
//createWebHashHistory // createMemoryHistory
import {createRouter, createWebHistory} from "vue-router";
import history from "@/assets/history.json";

const redirectToCurrentYear = (to) => {
  console.log(`router: origin = ${window.location.origin}`);
  console.log(`router: latestYear = ${history.latestYear}`);
  console.log(`router: to.path = ${to.path}`);
  const url = new URL(window.location.href);
  // Construct new URL with the latestYear and existing query/hash
  const newUrl = `${window.location.origin}/${history.latestYear}${to.path}${url.search}${url.hash}`;
  console.log(`router: redirecting to ${newUrl}`);
  window.location = newUrl;
};

console.log(`routes/index.js: redirectToCurrentYear = ${redirectToCurrentYear}`);

// {
//   path: "/:pathMatch(.*)*",
//   beforeEnter: (to, from, next) => {
//     console.log("router: catch-all route triggered");
//     if (to.path.match(/^\/\d{4}/)) {
//       next();
//       return;
//     }
//     redirectToCurrentYear(to);
//   }
//   // redirect: "/"
// },

const routes = [
  // {path: "/", beforeEnter: redirectToCurrentYear},
  // redirect everything else to the current year / home
  {
    path: "/",
    name: "Root",
    beforeEnter: (to, from, next) => {
      console.log("router: Root path matched exactly");
      window.location = `${window.location.origin}/${history.latestYear}`;
    }
  },
  {path: "/Masterclass", name: "StaticMasterclass", beforeEnter: redirectToCurrentYear},
  {path: "/Program", name: "StaticProgram", beforeEnter: redirectToCurrentYear},
  {path: "/Registration", name: "StaticRegistration", beforeEnter: redirectToCurrentYear},
  {path: "/Registration/Form", name: "StaticRegistrationForm", beforeEnter: redirectToCurrentYear},
  {path: "/People", name: "StaticPeople", beforeEnter: redirectToCurrentYear},
  {path: "/Contact", name: "StaticContact", beforeEnter: redirectToCurrentYear},
  {path: "/Venue", name: "StaticVenue", beforeEnter: redirectToCurrentYear},
  {path: "/Sponsors", name: "StaticSponsors", beforeEnter: redirectToCurrentYear},
  {path: "/Showcase", name: "StaticShowcase", beforeEnter: redirectToCurrentYear},
  {
    path: "/About",
    name: "About",
    component: () => import("../views/About.vue")
  },
  {
    path: "/Admin",
    name: "Admin",
    component: () => import("../views/Admin.vue")
  },
  {
    path: "/Blog/:specification*",
    name: "Blog",
    beforeEnter(to) {
      if (location.host.toLowerCase().includes("vizbi.org")) {
        window.location.href = `https://www.vizbi.org${to.path}`;
      }
    },
    component: () => import("../views/Blog.vue")
  },
  {
    path: "/Posters/:specification*",
    name: "Posters",
    component: () => import("../views/Posters/Index.vue"),
    children: [
      {
        path: "Upload",
        name: "Upload",
        component: () => import("../views/Posters/Upload.vue")
      }
    ],
    beforeEnter(to, from, next) {
      if (location.host.toLowerCase().includes("vizbi.org")) {
        // on production server only,
        //console.log(`re-directing to legacy server`);
        let url = `https://www.vizbi.org${to.path}`;
        if (to.query && Object.keys(to.query).length > 0) {
          //console.log(`to.query = ${JSON.stringify(to.query)}`);
          url += Object.keys(to.query).reduce((previous, current) => {
            return previous + current + "=" + to.query[current];
          }, "?");
        } else {
          //console.log(`empty to.query: ${JSON.stringify(to.query)}`);
        }
        //console.log(`url = ${JSON.stringify(url)}`);
        window.location.href = url;
      } else {
        next();
      }
    }
  },
  {
    path: "/Breakout/:specification*",
    name: "Breakout",
    beforeEnter(to) {
      window.location.href = `https://www.vizbi.org${to.path}`;
    }
  },
  {
    path: "/Lightning/:specification*",
    name: "Lightning",
    beforeEnter(to) {
      window.location.href = `https://www.vizbi.org${to.path}`;
    }
  },
  {
    path: "/Photos",
    name: "Photos",
    component: () => import("../views/Photos.vue"),
    children: [{path: ":photoYear(20\\d\\d)"}]
  },
  {
    path: "/Plus:specification*",
    name: "Plus",
    beforeEnter(to) {
      window.location.href = `https://www.vizbi.org${to.path}`;
    }
  },
  {
    path: "/Mail",
    name: "Mail",
    component: () => import("../views/Mail.vue")
  },
  {
    path: "/SignUp",
    name: "SignUp",
    component: () => import("../views/SignUp.vue")
  },
  {
    path: "/Live",
    name: "Live",
    component: () => import("../views/Live.vue")
  },
  {
    path: "/Test",
    name: "Test",
    component: () => import("../views/Test.vue"),
    beforeEnter(to, from, next) {
      // https://stackoverflow.com/q/58971222
      if (location.host.toLowerCase().includes("vizbi.org")) {
        console.log(`redirect future years to current year`);
        //window.location = `${window.location}/${history.latestYear}`;
        next({path: "/"});
      } else {
        next();
      }
    }
  },
  {
    path: "/Terms",
    name: "Terms",
    component: () => import("../views/Terms.vue")
  },
  {
    path: "/Videos",
    name: "Videos",
    component: () => import("../views/Videos.vue"),
    children: [
      {
        path: "",
        name: "Videos_Gallery",
        component: () => import("../components/VideoGallery.vue")
      },
      {
        path: ":year(20\\d\\d)",
        name: "Videos_Year",
        component: () => import("../components/VideoGallery.vue")
        // children: [
        //   {
        //     path: ":session(\\w+)",
        //     name: "Videos_Year_Session",
        //     component: () => import("../components/VideoGallery.vue")
        //   }
        // ]
      },
      {
        path: ":session([A-Z][a-zA-Z]*)",
        name: "Videos_Session",
        component: () => import("../components/VideoGallery.vue")
      },
      {
        path: ":vimeoHash(\\w{8,11})",
        name: "Video",
        component: () => import("../components/VideoBespoke.vue")
      }
    ]
  },
  {
    path: "/:year(201\\d)",
    name: "YearRedirect",
    beforeEnter(to) {
      window.location.href = `https://www.vizbi.org${to.path}`;
    }
  },
  {
    path: "/:year(202\\d)", // year(20\\d\\d)
    beforeEnter(to, from, next) {
      to = to.path.match(/\/(\d\d\d\d)/)[1];
      // https://stackoverflow.com/q/58971222
      if (to > history.latestYear && location.host.toLowerCase().includes("vizbi.org")) {
        console.log(`redirect future years to current year`);
        //window.location = `${window.location}/${history.latestYear}`;
        next({path: `/${history.latestYear}`});
      } else {
        next();
      }
    },
    name: "Year",
    component: () => import("../views/Year/Index.vue"),
    children: [
      {
        path: "",
        name: "AboutYear",
        component: () => import("../views/Year/About.vue")
      },
      {
        path: "People",
        name: "People",
        component: () => import("../views/Year/People.vue")
      },
      {
        path: "Program",
        name: "Program",
        component: () => import("../views/Year/Program.vue")
      },
      {
        path: "Contact",
        name: "Contact",
        component: () => import("../views/Year/Contact.vue")
      },
      {
        path: "VR",
        name: "VR",
        component: () => import("../views/Year/VR.vue")
      },
      {
        path: "Masterclass",
        name: "Masterclass",
        component: () => import("../views/Year/Masterclass.vue")
      },
      {
        path: "Showcase",
        name: "Showcase",
        component: () => import("../views/Year/Showcase.vue")
      },
      {
        path: "Platform",
        name: "Platform",
        component: () => import("../views/Year/Platform.vue")
      },
      {
        path: "Workshop",
        name: "Workshop",
        component: () => import("../views/Year/Workshop.vue")
      },
      {
        path: "Sponsors",
        name: "Sponsors",
        component: () => import("../views/Year/Sponsors.vue")
      },
      // name='Poster' not 'Posters': short and distinct from vizbi.org/Posters
      {
        path: "Posters",
        name: "Poster",
        component: () => import("../views/Year/Posters.vue")
      },
      {
        path: "Registration",
        name: "Registration",
        component: () => import("../views/Year/Registration.vue")
      },
      {
        path: "Registration/Form",
        name: "Form",
        component: () => import("../views/Year/Form.vue")
      },
      {
        path: "Venue",
        name: "Venue",
        component: () => import("../views/Year/Venue.vue")
      },
      {
        path: "Test",
        name: "TestYear",
        component: () => import("../views/Year/Test.vue")
      },
      {
        path: "Live",
        name: "LiveYear",
        component: () => import("../views/Live.vue")
      }
    ]
  }
];

console.log("routes/index.js: ", routes[2]);

//component: () => import(/* webpackChunkName: "about" */ 'About.vue')
// scroll solution below from https://stackoverflow.com/q/54535838
const router = createRouter({
  history: createWebHistory(), // createMemoryHistory(), // createWebHashHistory
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash && to.hash.length > 60) {
      // "hash.length > 60" prevents scrollBehavior when using Authenticate.vue
      return;
    }
    if (savedPosition) {
      //console.log(`router: savedPosition = ${stringify(savedPosition)}`);
      return savedPosition;
    }
    if (to.hash) {
      // Scroll to named anchor once page is loaded
      const hash = to.hash;
      let handleScrollToAnchor = (hash) => {
        if (document.querySelector(hash)) {
          // excludes cases where the hash is used to filter, not navigation
          document.querySelector(hash).scrollIntoView({
            behavior: "smooth",
            block: "start"
          });
        }
      };
      let timer = setInterval(() => {
        if (document.readyState === "complete") {
          clearInterval(timer);
          handleScrollToAnchor(hash);
        }
      }, 300);
      // may not need this, but we do it anyway
      return {
        selector: decodeURI(to.hash),
        offset: {
          x: 0,
          y: 80
        }
      };
    }
  }
});

console.log("routes/index.js: router.getRoutes() = ", router.getRoutes());

// TODO: move to separate file
// code below ensures router changes always go to top of new page
// however, it doesn't seem to stop navigation to page sections ('#')
// https://renatello.com/vue-js-scroll-top/
// See also https://stackoverflow.com/q/69109169/how-to-navigate-to-a-specific-elements-id-using-vue-router -->
router.beforeEach((to, from, next) => {
  console.log(`router: global guard called`);
  window.scrollTo(0, 0);
  try {
    next();
    if (localStorage.getItem("reloaded")) {
      localStorage.removeItem("reloaded"); // remove flag if set
    }
  } catch (error) {
    console.error("Error loading next page");
    // The error may be caused by 'TheImageBug'
    // If so, a simple page reload seems to fix this
    // https://stackoverflow.com/q/65955732
    if (!localStorage.getItem("reloaded")) {
      console.error("Will try one reload (only)");
      localStorage.setItem("reloaded", true); // set flag
      location.reload();
    } else {
      console.error("The page has already been re-loaded");
      localStorage.removeItem("reloaded"); // remove flag if set
      console.error(`Error: ${error}`); // print error to console
    }
  }
});

router.afterEach(() => {
  // Make sure meta-pixel 'fbq' is defined (might not be in initial page load)
  if (typeof window.fbq !== "undefined") {
    window.fbq("track", "PageView"); // tells pixel to track SPA page transition
  }
});

console.log("router: created");

export default router;
